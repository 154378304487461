<template>
    <div class="structure">
        <div class="structure__header">
            <h2 class="structure__title">Структура компании</h2>
            <div v-if="current_user.is_admin" class="structure__controls">
                <div class="structure__controls-wrap">
                    <add-structure-button />
                    <a
                        @click="addStructure(struct)"
                        href="javascript:void(0);"
                        class="structure__controls--add-depart"
                    >
                        <i class="fa fa-plus mr-5"></i>
                        Добавить отдел
                    </a>
                </div>
            </div>
        </div>
        <organization-chart
            class="structure__tree"
            :class="{ 'structure__tree--visible': isItemLoaded }"
            :datasource="struct"
            v-dragscroll
        >
            <template slot-scope="{ nodeData }">
                <item
                    :load-data="loadData"
                    @onRemove="removeStructure"
                    :on-add="addStructure"
                    @onEdit="editStructure"
                    @item-loaded="itemLoaded"
                    :show-structures="showStructures"
                    :hide-structures="hideStructures"
                    :data="nodeData"
                    :key="new Date().getTime()"
                />
                <button
                    v-if="nodeData.id === 1"
                    type="button"
                    class="structure__btn-show-all"
                    @click="onReveal()"
                >
                    {{ getTextRevealBtn }}
                </button>
            </template>
        </organization-chart>
        <loading-spinner v-if="!isPageLoaded || !isItemLoaded" />
    </div>
</template>

<script>
    import session from '@/api/session'
    import { mapState } from 'vuex'
    import OrganizationChart from 'vue-organization-chart'
    import 'vue-organization-chart/dist/orgchart.css'
    import Icon from '@/components/icon/Icon'

    export default {
        name: 'Page',
        components: {
            AddStructureButton: () =>
                import('@/components/staff/detail/button/AddStructureButton'),
            OrganizationChart,
            LoadingSpinner: () => import('@/components/LoadingSpinner'),
            Item: () => import('./tree/Item'),
            Icon
        },
        data() {
            return {
                isPageLoaded: false,
                isItemLoaded: false,
                struct: {},
                isRevealStructure: false,
            }
        },
        computed: {
            ...mapState('default_data', ['current_user']),
            getTextRevealBtn() {
                return this.isRevealStructure ? 'Свернуть' : 'Вся структура'
            },
        },
        methods: {
            itemLoaded() {
                this.isItemLoaded = true
            },

            addStructure(data, expandHandler) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const CreateStructure = () => import(`./modals/CreateStructure`)
                    this.$modal.show(
                        CreateStructure,
                        {
                            onCreate: this.afterCreateStruct,
                            parent: data,
                            expandHandler: expandHandler
                        },
                        {
                            name: 'CreateStructure',
                            scrollable: true,
                            height: 'auto',
                            adaptive: true,
                            maxWidth: 740,
                            width: '100%',
                            pivotY: 0,
                            clickToClose: true
                        }
                    )
                }
            },

            editStructure(data) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    const CreateStructure = () => import(`./modals/CreateStructure`)
                    this.$modal.show(
                        CreateStructure,
                        {
                            struct_id: data.id,
                            onEdit: this.afterEditStruct
                        },
                        {
                            name: 'CreateStructure',
                            scrollable: true,
                            height: 'auto',
                            adaptive: true,
                            maxWidth: 740,
                            width: '100%',
                            pivotY: 0,
                            clickToClose: true
                        }
                    )
                }
            },

            async removeStructure(struct) {
                const isBlocked = this.isBlockedPortal();

                if (!isBlocked) {
                    this.$swal({
                        customClass: {
                            confirmButton: 'btn btn-lg btn-alt-success m-5',
                            cancelButton: 'btn btn-lg btn-alt-danger m-5'
                        },
                        title: 'Удалить отдел?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Да, удалить!',
                        cancelButtonText: 'Отменить'
                    }).then(async value => {
                        if (value.value) {
                            let swal = this.$swal({
                                title: 'Удаление...',
                                icon: 'info',
                                showConfirmButton: false,
                                allowOutsideClick: false,
                                html: '<i class="fa fa-2x fa-cog fa-spin"></i>'
                            })
                            try {
                                const request = await session.delete(
                                    `/api/v1/structure/${struct.id}/`
                                )
                                this.afterRemoveStruct(struct)
                                swal.close()
                                this.$swal({
                                    title: 'Отлично!',
                                    text: 'Отдел удален',
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 1400
                                })
                            } catch (err) {
                                console.log(err)
                                swal.close()
                                this.$swal({
                                    title: 'Ошибка!',
                                    text:
                                        'При обработке запроса произошла ошибка на сервере',
                                    icon: 'error',
                                    showConfirmButton: false,
                                    timer: 1400
                                })
                            }
                        }
                    })
                }
            },

            async loadData() {
                try {
                    const request = session.get('/api/v1/structure/')
                    const response = await request
                    this.struct = response.data[0]
                    this.struct.isRoot = true
                    this.showStructures(this.struct)
                } catch (error) {
                    console.error(error)
                }
            },

            showStructures(data) {
                this.$nextTick(() => {
                    const el = document.getElementById(data.id)
                    const el1 = el.closest('tbody').childNodes
                    const classes = ['nodes', 'lines']

                    setTimeout(() => {
                        el1.forEach(i => {
                            if (classes.includes(i.className)) {
                                i.style.transform = 'scale(1)'
                                i.style.opacity = 1
                            }
                        })
                    }, 100)
                })
            },

            hideStructures(data) {
                this.$nextTick(() => {
                    const el = document.getElementById(data.id)
                    const el1 = el.closest('tbody').childNodes
                    const classes = ['nodes', 'lines']

                    el1.forEach(i => {
                        if (classes.includes(i.className)) {
                            i.style.transform = 'scale(0)'
                            i.style.opacity = 0
                        }
                    })
                    setTimeout(() => {
                        data.children = []
                    }, 500)
                })
            },

            afterCreateStruct(data, callback) {
                const parentNode = this.getStruct(data.parent, this.struct)
                parentNode.children.push(data)
                parentNode.children_count += 1
                this.showStructures(parentNode)
            },

            afterRemoveStruct(data) {
                let parentNode = this.getStruct(
                    data.parent || this.struct.id,
                    this.struct
                )
                parentNode.children = parentNode.children.filter(
                    i => i.id !== data.id
                )
                parentNode.children_count -= 1
            },

            afterEditStruct(data) {
                let parentNode = this.getStruct(data.parent, this.struct)
                let oldParentNode = this.getStruct(data.old_parent, this.struct)
                if (data.parent !== data.old_parent) {
                    parentNode.children.push(data)
                    parentNode.children_count += 1
                    this.showStructures(parentNode)
                    oldParentNode.children = oldParentNode.children.filter(
                        i => i.id !== data.id
                    )
                    oldParentNode.children_count -= 1
                } else {
                    parentNode.children = parentNode.children.map(struct => {
                        if (struct.id === data.id) {
                            return data
                        }
                        return struct
                    })
                }
            },

            getStruct(id, currentNode) {
                var i, currentChild, result

                if (id == currentNode.id) {
                    return currentNode
                } else {
                    // Use a for loop instead of forEach to avoid nested functions
                    // Otherwise "return" will not work properly
                    for (i = 0; i < currentNode.children.length; i += 1) {
                        currentChild = currentNode.children[i]

                        // Search in the current child
                        result = this.getStruct(id, currentChild)

                        // Return the result if the node has been found
                        if (result !== false) {
                            return result
                        }
                    }

                    // The node has not been found and we have no more options
                    return false
                }
            },
            onReveal() {
                if (!this.isRevealStructure) {
                    this.getAllItems();
                } else {
                    this.hideChildren(this.struct.children);
                }
                this.isRevealStructure = !this.isRevealStructure;
            },
            async getAllItems() {
                try {
                    const request = session.get('/api/v1/structure/get_all/');
                    const response = await request;
                    this.struct = response.data[0];
                    this.struct.isRoot = true;
                    this.showChildren(this.struct.children);
                } catch (error) {
                    console.error(error)
                }
            },
            showChildren(children) {
                children.forEach(child => {
                    this.showStructures(child);
                    this.showChildren(child.children);
                })
            },
            hideChildren(children) {
                children.forEach(child => {
                    this.hideStructures(child);
                    this.hideChildren(child.children);
                })
            }
        },
        async mounted() {
            try {
                await this.loadData()
                this.isPageLoaded = true
                this.showStructures(this.struct)
            } catch (error) {
                console.error(error)
            }
            const orgChart = document.querySelector(".orgchart-container");
            orgChart.scrollLeft = (orgChart.scrollWidth - orgChart.offsetWidth) / 2;
        }
    }
</script>

<style lang="scss">
    @import '#sass/v-style';

    $support-line: #e0e3f0;
    $yellow: #fbcf56;

    .page-structure {
        .popover {
            width: 100% !important;
            max-width: 400px !important;
            &-inner {
                max-width: 100% !important;
            }
        }
    }

    .structure {
        &__title {
            margin: 0 0 15px 15px;
        }
        &__header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0 0 -15px -15px;
        }
        &__controls {
            overflow-x: auto;
            overflow-y: hidden;
            margin: 0 0 15px 15px;
            &-wrap {
                display: flex;
                display: flex;
                min-width: max-content;
            }
            &--add-depart {
                border-radius: 50px;
                padding: 6px 22px;
                color: #000;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                margin-left: 20px;
                border: 2px solid #fbcf56;
                transition: background-color 200ms ease;

                &:hover {
                    background-color: #fbcf56;
                    color: #000;
                }
            }
        }

        &__tree {
            display: block;
            border: none;
            margin-top: 20px;
            width: 100%;
            height: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all 1s ease;
            &--visible {
                opacity: 1;
                visibility: visible;
            }
            .nodes,
            .lines {
                transition: all 1s ease;
                opacity: 0;
                transform: scale(0);
            }
            .node {
                width: auto;
                overflow: hidden;
                padding: 4px;
            }
            .orgchart {
                background: #fff;
                min-width: 100%;
                cursor: grab !important;
                &:active {
                    cursor: grabbing !important;
                }
            }
            .orgchart .node.focused,
            .orgchart .node {
                border: none;
                margin: 0;
                padding-top: 0;
                padding-bottom: 0;
                overflow: visible;
                &:hover {
                    background: none;
                }
            }

            /*Кастомизация линий*/
            $line: $support-line;
            .topLine {
                border-top: 2px solid $line !important;
            }
            .rightLine {
                border-right: 1px solid $line !important;
            }
            .downLine {
                background-color: $line !important;
            }
            .leftLine {
                border-left: 1px solid $line !important;
            }
        }
        &__btn-show-all {
            position: absolute;
            top: 50%;
            right: -20px;
            border-radius: 50px;
            transform: translate(100%, -50%);
            padding: 6px 22px;
            border: 2px solid $yellow;
            background-color: transparent;
            color: #000 !important;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            white-space: nowrap;
            cursor: pointer;
            transition: background-color 200ms ease;

            &:hover {
                background-color: $yellow;
            }
        }
    }
</style>
